import React from "react";

const Github = ({width = 17}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width / 17 * 16.584} viewBox="0 0 17 16.584">
        <g transform="translate(131 -96.365)">
            <path
                d="M15.86,9.6A8.463,8.463,0,0,0,12.766,6.5,8.314,8.314,0,0,0,8.5,5.365,8.315,8.315,0,0,0,4.233,6.5,8.462,8.462,0,0,0,1.14,9.6,8.314,8.314,0,0,0,0,13.865a8.261,8.261,0,0,0,1.621,5A8.325,8.325,0,0,0,5.81,21.933a.5.5,0,0,0,.443-.077.433.433,0,0,0,.144-.332q0-.033-.006-.6t-.006-.985l-.255.044a3.247,3.247,0,0,1-.614.039,4.68,4.68,0,0,1-.769-.077,1.719,1.719,0,0,1-.742-.332,1.4,1.4,0,0,1-.487-.681l-.111-.255a2.765,2.765,0,0,0-.349-.564,1.336,1.336,0,0,0-.481-.421L2.5,17.639a.813.813,0,0,1-.144-.133.607.607,0,0,1-.1-.155q-.033-.078.055-.127a.718.718,0,0,1,.321-.049l.221.033a1.616,1.616,0,0,1,.548.266,1.786,1.786,0,0,1,.537.575,1.951,1.951,0,0,0,.614.692,1.3,1.3,0,0,0,.725.238,3.147,3.147,0,0,0,.631-.055,2.2,2.2,0,0,0,.5-.166,1.793,1.793,0,0,1,.542-1.14,7.578,7.578,0,0,1-1.134-.2,4.517,4.517,0,0,1-1.04-.432,2.98,2.98,0,0,1-.891-.741A3.563,3.563,0,0,1,3.3,15.082a5.526,5.526,0,0,1-.227-1.66,3.228,3.228,0,0,1,.874-2.28,2.975,2.975,0,0,1,.077-2.258,1.551,1.551,0,0,1,.952.149A6.654,6.654,0,0,1,5.9,9.46q.293.177.47.3a7.993,7.993,0,0,1,4.25,0l.421-.265a5.956,5.956,0,0,1,1.018-.487,1.45,1.45,0,0,1,.9-.122,2.947,2.947,0,0,1,.088,2.258,3.229,3.229,0,0,1,.874,2.28,5.588,5.588,0,0,1-.227,1.666,3.425,3.425,0,0,1-.586,1.162,3.094,3.094,0,0,1-.9.736,4.526,4.526,0,0,1-1.04.432,7.57,7.57,0,0,1-1.134.2,1.971,1.971,0,0,1,.575,1.571v2.335a.441.441,0,0,0,.138.332.481.481,0,0,0,.437.077,8.324,8.324,0,0,0,4.189-3.071,8.262,8.262,0,0,0,1.621-5A8.321,8.321,0,0,0,15.86,9.6Z"
                transform="translate(-131 91)" fill="#707070"/>
        </g>
    </svg>
);

export default Github;
