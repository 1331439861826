import React from "react";

const Email = ({width = 17}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width / 17 * 17.15} viewBox="0 0 17 17.15">
        <g transform="translate(-2.15)">
            <path
                d="M16.8,2.162A8.281,8.281,0,0,0,10.97,0,8.707,8.707,0,0,0,4.729,2.46,8.122,8.122,0,0,0,2.15,8.582,8.376,8.376,0,0,0,4.6,14.621a8.807,8.807,0,0,0,6.619,2.53,12.393,12.393,0,0,0,4.923-1.032,1.049,1.049,0,0,0,.567-1.354h0a1.046,1.046,0,0,0-1.385-.567,10.362,10.362,0,0,1-4.107.9,6.308,6.308,0,0,1-4.944-1.945A6.6,6.6,0,0,1,4.526,8.593,6.5,6.5,0,0,1,6.4,3.845a6.206,6.206,0,0,1,4.6-1.9,6.109,6.109,0,0,1,4.247,1.564,5.078,5.078,0,0,1,1.732,3.936,4.437,4.437,0,0,1-.794,2.7,2.147,2.147,0,0,1-1.648,1.078c-.308,0-.462-.164-.462-.5a9.21,9.21,0,0,1,.059-.948l.651-5.321H12.548l-.143.521a2.906,2.906,0,0,0-1.875-.7A3.587,3.587,0,0,0,7.762,5.563,4.771,4.771,0,0,0,6.611,8.876,4.789,4.789,0,0,0,7.632,12.07a3.107,3.107,0,0,0,2.46,1.214,2.789,2.789,0,0,0,2.2-1.078,2.275,2.275,0,0,0,2.026,1.032,4.306,4.306,0,0,0,3.4-1.714A6.227,6.227,0,0,0,19.15,7.393,6.849,6.849,0,0,0,16.8,2.162Zm-5.157,8.05a1.736,1.736,0,0,1-1.417.8,1.02,1.02,0,0,1-.9-.588,2.837,2.837,0,0,1-.346-1.462,2.887,2.887,0,0,1,.483-1.742,1.441,1.441,0,0,1,1.2-.665,1.493,1.493,0,0,1,1.1.5,1.823,1.823,0,0,1,.483,1.326A3.028,3.028,0,0,1,11.645,10.213Z"
                fill="#707070"/>
        </g>
    </svg>
);

export default Email;
