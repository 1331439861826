import React from "react";

const Signature = ({width = 46.343}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width / 46.343 * 22.424} viewBox="0 0 46.343 22.424">
        <g transform="translate(-171.12 162.424)">
            <path
                d="M72.038,50.8l.631-.481L71.48,50.27l-4.155,6.6c-.337.534,1.069.164,1.281-.039l6.895-6.49-1.334.111a99.953,99.953,0,0,1-11.94,21.589l1.338-.2c-1.444-2.239-.154-4.786,1.358-6.63a14.339,14.339,0,0,1,2.6-2.46c.428-.313,5.224-3.183,5.426-1.666.053.39,1.093,0,1.247-.12A8.8,8.8,0,0,1,75.986,59.6l-.963-.221A12.48,12.48,0,0,0,72.1,63.337c-.178.38.867.149.963.12a27.443,27.443,0,0,0,4.815-2.191l-1.117.034c.091,1.141-1.131,2.056.563,2.171,1.57.111,3.727-.934,5.128-1.55a22.769,22.769,0,0,0,9.629-8.007c.337-.481-.9-.236-1.059-.135a30.28,30.28,0,0,0-5.455,4.247c-1.382,1.372-3.423,3.183-2.793,5.325.14.481,1.242-.043,1.391-.294a6.4,6.4,0,0,1,3.154-2.889l-.91.029c.515.385-1.647,1.233-1.748,1.324-.525.51-.549.741-.135,1.3.963,1.3,4.6,1.531,6.042,1.04.963-.332,1.883-1.416.424-1.844-1.281-.376-2.181.833-2.518,1.849-.159.481,1.189.043,1.334-.12a6.846,6.846,0,0,0,1.358-2.561,6.688,6.688,0,0,1,.592-1.584c1.233-1.781,2.836-1.127,4.333-.818a43.115,43.115,0,0,0,10.814.77c.448,0,1.5-.727.573-.679a41.844,41.844,0,0,1-8.666-.438c-1.757-.284-3.645-1-5.445-.722-3.471.534-2.889,3.929-4.815,6.067l1.334-.125c.294-.9-.337-.323-1.006-.332a4.544,4.544,0,0,1-2.143-.52c-1.208-1.233.135-1.16.751-1.565s.924-1.04.25-1.546a1.059,1.059,0,0,0-.91.029A8.118,8.118,0,0,0,82.8,63.246l1.391-.289c-1-3.37,5.3-7.41,7.7-8.893l-1.059-.13a21.555,21.555,0,0,1-6.148,6.042,19.97,19.97,0,0,1-4.333,2.109c-.559.193-1.969.756-2.407-.063.106.2.25-1.2.255-1.112-.029-.361-.963-.043-1.112.034a25.952,25.952,0,0,1-4.564,2.022l.963.12a11.281,11.281,0,0,1,2.634-3.611c.563-.525-.722-.38-.963-.221A13.91,13.91,0,0,0,73.117,60.8l1.242-.116c-.337-2.5-6.442.91-7.3,1.478-2.889,1.9-7.222,6.331-4.877,10.024.236.366,1.131.082,1.338-.2A100.862,100.862,0,0,0,75.553,50.232c.193-.481-1.2,0-1.334.12l-6.895,6.514,1.281-.039,4.16-6.606c.332-.53-1.011-.188-1.189-.048l-.631.481c-.592.481.794.371,1.093.135Z"
                transform="translate(109.612 -212.36)"/>
        </g>
    </svg>
);

export default Signature;
